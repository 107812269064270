/* eslint-disable eqeqeq */
import { trackPromise } from 'react-promise-tracker';
import {apiHost} from '../../../config';
const httpUtil = require('../../../utils/HttpUtils');
const sysAlert = require('../../../utils/SysAlert');


export const getAsyncUserList = async (obj) =>{
    try{
        let url = apiHost + '/admin/user?';
        let conditions = httpUtil.objToUrl(obj);
        console.log(obj)
        console.log(conditions)
        url = conditions.length > 0 ? url + "&" + conditions : url;
        console.log(url)
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        console.log(res.data)
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取用户信息失败") 
            return {}
        }
        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}

export const getAsyncUserProfileList = async (obj) =>{
    try{
        let url = apiHost + '/admin/profile?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取简历列表信息失败") 
            return {}
        }
        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}


export const updateAsyncUserStatus = async (params) => {
    try {
        let url = apiHost + `/admin/user/${params.userId}/status/${params.status}`;
        let res = await trackPromise(httpUtil.httpPut(url, params));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("操作失败") 
            return null
        }     
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
        return null
    }
}
export const getAsyncUserProfileOpt = async (obj) =>{
    try{
        let url = apiHost + '/admin/profileOpt?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取简历列表信息失败") 
            return {}
        }        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}

export const getAsyncUserProfileEdu = async (obj) =>{
    try{
        let url = apiHost + '/admin/profileEdu?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取简历列表信息失败") 
            return {}
        }        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}

export const getAsyncUserProfileWork = async (obj) =>{
    try{
        let url = apiHost + '/admin/profileWork?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取简历列表信息失败") 
            return {}
        }        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}

export const getAsyncUserProfileProject = async (obj) =>{
    try{
        let url = apiHost + '/admin/profileProject?';
        let conditions = httpUtil.objToUrl(obj);
        url = conditions.length > 0 ? url + "&" + conditions : url;
        const res = await trackPromise(httpUtil.httpAsyncGet(url));
        if(res.success){
            return res.data
        }else{
            sysAlert.WarningAlert("获取简历列表信息失败") 
            return {}
        }        
    } catch (err) {
        sysAlert.ErrorAlert(err.message);
        return err
    }
}

export const exportUserProfile = (params) => {
    console.log(params)
    try {
        // 基本检索URL
        let url = apiHost + '/admin/profile.csv?';        
        let conditions = httpUtil.objToUrl(params);
        // 检索URL
        url = conditions.length > 0 ? url + "&" + conditions : url;
        httpUtil.httpDownload(url,"user_profile.csv");
    } catch (err) {
        sysAlert.ErrorAlert(err.message)
    }
};